/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @copyright 2020
 * @license MIT
 */

// import { faAdobe } from '@fortawesome/free-brands-svg-icons/faAdobe';
// import { faGitAlt } from '@fortawesome/free-brands-svg-icons/faGitAlt';
// import { faLinux } from '@fortawesome/free-brands-svg-icons/faLinux';
// import { faNodeJs } from '@fortawesome/free-brands-svg-icons/faNodeJs';
// import { faPhp } from '@fortawesome/free-brands-svg-icons/faPhp';
// import { faPython } from '@fortawesome/free-brands-svg-icons/faPython';
// import { faRust } from '@fortawesome/free-brands-svg-icons/faRust';
// import { faUbuntu } from '@fortawesome/free-brands-svg-icons/faUbuntu';
// import { faWindows } from '@fortawesome/free-brands-svg-icons/faWindows';
// import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
// import { faBalanceScale } from '@fortawesome/free-solid-svg-icons/faBalanceScale';
// import { faBoxOpen } from '@fortawesome/free-solid-svg-icons/faBoxOpen';
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
// import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
// import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
// import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs';
// import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
// import { faCubes } from '@fortawesome/free-solid-svg-icons/faCubes';
// import { faDatabase } from '@fortawesome/free-solid-svg-icons/faDatabase';
// import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
// import { faEraser } from '@fortawesome/free-solid-svg-icons/faEraser';
// import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
// import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
// import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
// import { faFile } from '@fortawesome/free-solid-svg-icons/faFile';
// import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';
// import { faFileArchive } from '@fortawesome/free-solid-svg-icons/faFileArchive';
// import { faFileCode } from '@fortawesome/free-solid-svg-icons/faFileCode';
// import { faFileExcel } from '@fortawesome/free-solid-svg-icons/faFileExcel';
// import { faFileImage } from '@fortawesome/free-solid-svg-icons/faFileImage';
// import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
// import { faFileWord } from '@fortawesome/free-solid-svg-icons/faFileWord';
// import { faFilm } from '@fortawesome/free-solid-svg-icons/faFilm';
// import { faFistRaised } from '@fortawesome/free-solid-svg-icons/faFistRaised';
// import { faFolder } from '@fortawesome/free-solid-svg-icons/faFolder';
// import { faFolderOpen } from '@fortawesome/free-solid-svg-icons/faFolderOpen';
// import { faFolderPlus } from '@fortawesome/free-solid-svg-icons/faFolderPlus';
// import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
// import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
// import { faLevelUpAlt } from '@fortawesome/free-solid-svg-icons/faLevelUpAlt';
// import { faList } from '@fortawesome/free-solid-svg-icons/faList';
// import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
// import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
// import { faMusic } from '@fortawesome/free-solid-svg-icons/faMusic';
// import { faObjectGroup } from '@fortawesome/free-solid-svg-icons/faObjectGroup';
// import { faPaste } from '@fortawesome/free-solid-svg-icons/faPaste';
// import { faRunning } from '@fortawesome/free-solid-svg-icons/faRunning';
// import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
// import { faShareAlt } from '@fortawesome/free-solid-svg-icons/faShareAlt';
// import { faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons/faSortAmountDownAlt';
// import { faSortAmountUpAlt } from '@fortawesome/free-solid-svg-icons/faSortAmountUpAlt';
// import { faTerminal } from '@fortawesome/free-solid-svg-icons/faTerminal';
// import { faTh } from '@fortawesome/free-solid-svg-icons/faTh';
// import { faThLarge } from '@fortawesome/free-solid-svg-icons/faThLarge';
// import { faThList } from '@fortawesome/free-solid-svg-icons/faThList';
// import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
// import { faToggleOff } from '@fortawesome/free-solid-svg-icons/faToggleOff';
// import { faToggleOn } from '@fortawesome/free-solid-svg-icons/faToggleOn';
// import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
// import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
// import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChonkyIconName, ChonkyIconProps } from 'chonky';
import { AppstoreOutlined, ArrowDownOutlined, BranchesOutlined, CheckOutlined, ClearOutlined, CodeOutlined, CopyOutlined, DatabaseOutlined, DeleteOutlined, DownloadOutlined, DownOutlined, DragOutlined, ExclamationOutlined, ExportOutlined, EyeInvisibleOutlined, FileExcelOutlined, FileImageOutlined, FileOutlined, FilePdfOutlined, FileTextOutlined, FileWordOutlined, FileZipOutlined, FolderAddOutlined, FolderOpenOutlined, FolderOutlined, GroupOutlined, InfoCircleOutlined, KeyOutlined, LinuxOutlined, LoadingOutlined, LockOutlined, MinusOutlined, RightOutlined, SearchOutlined, SettingOutlined, ShareAltOutlined, SnippetsOutlined, SortAscendingOutlined, SortDescendingOutlined, StopOutlined, UnorderedListOutlined, UploadOutlined, UpOutlined, UserOutlined, WindowsOutlined } from '@ant-design/icons';
import React from 'react';
import { FolderIcon } from './folder';

const IconMap: { [iconName in ChonkyIconName]: any } = {
    // Misc
    [ChonkyIconName.loading]: <LoadingOutlined />,
    [ChonkyIconName.dropdown]: <DownOutlined />,
    [ChonkyIconName.placeholder]: <MinusOutlined />,

    // File Actions: Drag & drop
    [ChonkyIconName.dndDragging]: <DragOutlined />,
    [ChonkyIconName.dndCanDrop]: <ArrowDownOutlined />,
    [ChonkyIconName.dndCannotDrop]: <StopOutlined />,

    // File Actions: File operations
    [ChonkyIconName.openFiles]: <FolderOpenOutlined />,
    [ChonkyIconName.openParentFolder]: <UpOutlined />,
    [ChonkyIconName.copy]: <CopyOutlined />,
    [ChonkyIconName.paste]: <SnippetsOutlined />,
    [ChonkyIconName.share]: <ShareAltOutlined />,
    [ChonkyIconName.search]: <SearchOutlined />,
    [ChonkyIconName.selectAllFiles]: <GroupOutlined />,
    [ChonkyIconName.clearSelection]: <ClearOutlined />,

    // File Actions: Sorting & options
    [ChonkyIconName.sortAsc]: <SortAscendingOutlined />,
    [ChonkyIconName.sortDesc]: <SortDescendingOutlined />,
    [ChonkyIconName.toggleOn]: <CheckOutlined />,
    [ChonkyIconName.toggleOff]: <MinusOutlined />,

    // File Actions: File Views
    [ChonkyIconName.list]: <UnorderedListOutlined />,
    [ChonkyIconName.compact]: null,
    [ChonkyIconName.smallThumbnail]: <AppstoreOutlined />,
    [ChonkyIconName.largeThumbnail]: null,

    // File Actions: Unsorted
    [ChonkyIconName.folderThumbnail]: <FolderIcon />,
    [ChonkyIconName.folder]: <FolderOutlined />,
    [ChonkyIconName.folderCreate]: <FolderAddOutlined />,
    [ChonkyIconName.folderOpen]: <FolderOpenOutlined />,
    [ChonkyIconName.folderChainSeparator]: <RightOutlined />,
    [ChonkyIconName.download]: <DownloadOutlined />,
    [ChonkyIconName.upload]: <UploadOutlined />,
    [ChonkyIconName.trash]: <DeleteOutlined />,
    [ChonkyIconName.fallbackIcon]: <ExclamationOutlined />,

    // File modifiers
    [ChonkyIconName.symlink]: <ExportOutlined />,
    [ChonkyIconName.hidden]: <EyeInvisibleOutlined />,

    // Generic file types
    [ChonkyIconName.file]: <FileOutlined />,
    [ChonkyIconName.license]: null,
    [ChonkyIconName.code]: <CodeOutlined />,
    [ChonkyIconName.config]: <SettingOutlined />,
    [ChonkyIconName.model]: null,
    [ChonkyIconName.database]: <DatabaseOutlined />,
    [ChonkyIconName.text]: <FileTextOutlined />,
    [ChonkyIconName.archive]: <FileZipOutlined />,
    [ChonkyIconName.image]: <FileImageOutlined />,
    [ChonkyIconName.video]: null,
    [ChonkyIconName.info]: <InfoCircleOutlined />,
    [ChonkyIconName.key]: <KeyOutlined />,
    [ChonkyIconName.lock]: <LockOutlined />,
    [ChonkyIconName.music]: null,
    [ChonkyIconName.terminal]: null,
    [ChonkyIconName.users]: <UserOutlined />,

    // OS file types
    [ChonkyIconName.linux]: <LinuxOutlined />,
    [ChonkyIconName.ubuntu]: <LinuxOutlined />,
    [ChonkyIconName.windows]: <WindowsOutlined />,

    // Programming language file types
    [ChonkyIconName.rust]: null,
    [ChonkyIconName.python]: null,
    [ChonkyIconName.nodejs]: null,
    [ChonkyIconName.php]: null,

    // Development tools file types
    [ChonkyIconName.git]: <BranchesOutlined />,

    // Brands file types
    [ChonkyIconName.adobe]: null,

    // Other program file types
    [ChonkyIconName.pdf]: <FilePdfOutlined />,
    [ChonkyIconName.excel]: <FileExcelOutlined />,
    [ChonkyIconName.word]: <FileWordOutlined />,
    [ChonkyIconName.flash]: null,
} as const;

export const ChonkyIconFA: React.FC<ChonkyIconProps> = React.memo((props) => {
    const { icon } = props;

    let faIcon: any; // FontAwesome `IconDefinition`
    if (typeof icon === 'object') faIcon = icon;
    // else faIcon = IconMap[icon as keyof typeof IconMap] ?? IconMap.fallbackIcon;
    else faIcon = IconMap[icon as keyof typeof IconMap];

    // const faProps = {
    //     ...props,
    //     icon: faIcon,
    // } as const;
    return faIcon
    // return <FontAwesomeIcon {...faProps} />;
});
