import React from 'react'
export const FolderIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="36"
      viewBox="0 0 45 36"
    >
      <g>
        <path
          fill="#F2B64C"
          d="M3.93 36C1.758 36 0 34.262 0 32.113V2.22C0 .992 1.008 0 2.246 0h4.61c.652 0 1.289.203 1.816.586l3.164 2.273a3.121 3.121 0 001.82.586H37.59c1.242 0 2.246.993 2.246 2.22V8.77S20.219 36 3.93 36zm0 0"
        ></path>
        <path
          fill="#FCD054"
          d="M45 10.988v21.125a3.857 3.857 0 01-1.152 2.75A3.934 3.934 0 0141.07 36H3.93c2.172 0 3.93-1.742 3.93-3.887V10.988c0-1.226 1.007-2.218 2.245-2.218h32.649c1.242 0 2.246.992 2.246 2.218zm0 0"
        ></path>
      </g>
    </svg>
)