/* eslint-disable */
// DO NOT EDIT! Automatically generated from .jison
/* parser generated by jison 0.4.18 */
/*
  Returns a Parser object of the following structure:

  Parser: {
    yy: {}
  }

  Parser.prototype: {
    yy: {},
    trace: function(),
    symbols_: {associative list: name ==> number},
    terminals_: {associative list: number ==> name},
    productions_: [...],
    performAction: function anonymous(yytext, yyleng, yylineno, yy, yystate, $$, _$),
    table: [...],
    defaultActions: {...},
    parseError: function(str, hash),
    parse: function(input),

    lexer: {
        EOF: 1,
        parseError: function(str, hash),
        setInput: function(input),
        input: function(),
        unput: function(str),
        more: function(),
        less: function(n),
        pastInput: function(),
        upcomingInput: function(),
        showPosition: function(),
        test_match: function(regex_match_array, rule_index),
        next: function(),
        lex: function(),
        begin: function(condition),
        popState: function(),
        _currentRules: function(),
        topState: function(),
        pushState: function(condition),

        options: {
            ranges: boolean           (optional: true ==> token location info will include a .range[] member)
            flex: boolean             (optional: true ==> flex-like lexing behaviour where the rules are tested exhaustively to find the longest match)
            backtrack_lexer: boolean  (optional: true ==> lexer regexes are tested in order and for each matching regex the action code is invoked; the lexer terminates the scan when a token is returned by the action code)
        },

        performAction: function(yy, yy_, $avoiding_name_collisions, YY_START),
        rules: [...],
        conditions: {associative list: name ==> set},
    }
  }


  token location info (@$, _$, etc.): {
    first_line: n,
    last_line: n,
    first_column: n,
    last_column: n,
    range: [start_number, end_number]       (where the numbers are indexes into the input string, regular zero-based)
  }


  the parseError function receives a 'hash' object with these members for lexer and parser errors: {
    text:        (matched text)
    token:       (the produced terminal token, if any)
    line:        (yylineno)
  }
  while parser (grammar) errors will also provide these members, i.e. parser errors deliver a superset of attributes: {
    loc:         (yylloc)
    expected:    (string describing the set of expected tokens)
    recoverable: (boolean: TRUE when the parser has a error recovery rule available for this particular error)
  }
*/
var parser = (function () {
  var o = function (k, v, o, l) {
      for (o = o || {}, l = k.length; l--; o[k[l]] = v);
      return o
    },
    $V0 = [1, 60],
    $V1 = [1, 62],
    $V2 = [1, 63],
    $V3 = [1, 65],
    $V4 = [1, 66],
    $V5 = [1, 67],
    $V6 = [1, 68],
    $V7 = [1, 69],
    $V8 = [1, 80],
    $V9 = [1, 72],
    $Va = [1, 73],
    $Vb = [1, 74],
    $Vc = [1, 75],
    $Vd = [1, 99],
    $Ve = [1, 76],
    $Vf = [1, 100],
    $Vg = [1, 79],
    $Vh = [1, 51],
    $Vi = [1, 81],
    $Vj = [1, 82],
    $Vk = [1, 84],
    $Vl = [1, 83],
    $Vm = [1, 85],
    $Vn = [1, 96],
    $Vo = [1, 97],
    $Vp = [1, 98],
    $Vq = [1, 86],
    $Vr = [1, 87],
    $Vs = [1, 64],
    $Vt = [1, 70],
    $Vu = [1, 71],
    $Vv = [1, 77],
    $Vw = [1, 78],
    $Vx = [1, 53],
    $Vy = [1, 54],
    $Vz = [1, 55],
    $VA = [1, 61],
    $VB = [1, 88],
    $VC = [1, 89],
    $VD = [1, 90],
    $VE = [1, 91],
    $VF = [1, 92],
    $VG = [1, 93],
    $VH = [1, 94],
    $VI = [1, 95],
    $VJ = [1, 101],
    $VK = [1, 102],
    $VL = [1, 103],
    $VM = [1, 104],
    $VN = [1, 105],
    $VO = [1, 56],
    $VP = [1, 57],
    $VQ = [1, 58],
    $VR = [1, 59],
    $VS = [1, 115],
    $VT = [1, 111],
    $VU = [1, 114],
    $VV = [1, 112],
    $VW = [1, 113],
    $VX = [1, 118],
    $VY = [1, 117],
    $VZ = [1, 134],
    $V_ = [1, 149],
    $V$ = [1, 150],
    $V01 = [1, 157],
    $V11 = [
      5, 6, 7, 9, 13, 14, 15, 17, 18, 19, 20, 23, 25, 26, 27, 30, 33, 34, 35,
      37, 38, 41, 43, 45, 46, 49, 52, 54, 55, 56, 58, 59, 62, 64, 65, 66, 70,
      72, 74, 77, 78, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95,
      96, 97, 98, 99, 101
    ],
    $V21 = [
      5, 6, 7, 9, 13, 14, 15, 17, 18, 19, 20, 23, 25, 26, 27, 30, 33, 34, 35,
      37, 38, 41, 43, 45, 46, 49, 52, 54, 55, 56, 58, 59, 62, 64, 65, 66, 70,
      71, 72, 74, 77, 78, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94,
      95, 96, 97, 98, 99, 101
    ],
    $V31 = [
      5, 6, 7, 9, 13, 15, 17, 18, 19, 20, 23, 25, 26, 27, 30, 33, 34, 37, 38,
      41, 43, 45, 46, 49, 52, 54, 55, 56, 58, 59, 62, 64, 65, 66, 70, 72, 82,
      83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95
    ],
    $V41 = [5, 70, 72],
    $V51 = [5, 74],
    $V61 = [71, 101]
  var parser = {
    trace: function trace() {},
    yy: {},
    symbols_: {
      error: 2,
      Program: 3,
      Command: 4,
      EOF: 5,
      RESET: 6,
      BUILD: 7,
      ALL: 8,
      HELP: 9,
      Path: 10,
      MOTM: 11,
      OneArgCommand: 12,
      GET: 13,
      STRING: 14,
      SET: 15,
      Value: 16,
      SET_SAVE: 17,
      SET_RESTORE: 18,
      SET_RESET: 19,
      PRESET: 20,
      AddRepresentation: 21,
      EditRepresentation: 22,
      REMOVE: 23,
      RepresentationReference: 24,
      HIDE: 25,
      SHOW: 26,
      LIST: 27,
      EXPAND_KEY: 28,
      SELECTOR_KEY: 29,
      SELECT: 30,
      AS: 31,
      WordAll: 32,
      SELECTOR: 33,
      WITHIN: 34,
      NUMBER: 35,
      OF: 36,
      MATERIAL: 37,
      IDENTIFIER: 38,
      ModeCMD: 39,
      ColorCMD: 40,
      VIEW: 41,
      BASE_64: 42,
      UNIT: 43,
      DSSP: 44,
      SCALE: 45,
      ROTATE: 46,
      AxesList: 47,
      TRANSLATE: 48,
      CENTER: 49,
      GetURLBranch: 50,
      Screenshot: 51,
      LINE: 52,
      ArgList: 53,
      LISTOBJ: 54,
      REMOVEOBJ: 55,
      URL: 56,
      VIEW_KEY: 57,
      SCREENSHOT: 58,
      LOAD: 59,
      Url: 60,
      FILE_KEY: 61,
      ADD: 62,
      Description: 63,
      REP: 64,
      MODE: 65,
      COLOR: 66,
      Descriptor: 67,
      RepresentationOwnProperty: 68,
      RepresentationOwnPropertyOpts: 69,
      DESC_KEY: 70,
      '=': 71,
      DESC_KEY_OPTS: 72,
      AxesArg: 73,
      DESC_KEY_AXES: 74,
      Arg: 75,
      PathWoDescKey: 76,
      HEX: 77,
      BOOL: 78,
      Word: 79,
      CommandSetWoDESC_KEY: 80,
      DescKeys: 81,
      CLEAR: 82,
      FILE_LIST: 83,
      FILE_REGISTER: 84,
      FILE_DELETE: 85,
      PRESET_ADD: 86,
      PRESET_DELETE: 87,
      PRESET_UPDATE: 88,
      PRESET_RENAME: 89,
      PRESET_OPEN: 90,
      CREATE_SCENARIO: 91,
      RESET_SCENARIO: 92,
      DELETE_SCENARIO: 93,
      ADD_SCENARIO_ITEM: 94,
      LIST_SCENARIO: 95,
      PDB_KEY: 96,
      DELAY_KEY: 97,
      PRST_KEY: 98,
      DESCRIPTION_KEY: 99,
      CommandSet: 100,
      '.': 101,
      PresetPath: 102,
      '/': 103,
      HexOrNumber: 104,
      $accept: 0,
      $end: 1
    },
    terminals_: {
      2: 'error',
      5: 'EOF',
      6: 'RESET',
      7: 'BUILD',
      8: 'ALL',
      9: 'HELP',
      11: 'MOTM',
      13: 'GET',
      14: 'STRING',
      15: 'SET',
      17: 'SET_SAVE',
      18: 'SET_RESTORE',
      19: 'SET_RESET',
      20: 'PRESET',
      23: 'REMOVE',
      25: 'HIDE',
      26: 'SHOW',
      27: 'LIST',
      28: 'EXPAND_KEY',
      29: 'SELECTOR_KEY',
      30: 'SELECT',
      31: 'AS',
      33: 'SELECTOR',
      34: 'WITHIN',
      35: 'NUMBER',
      36: 'OF',
      37: 'MATERIAL',
      38: 'IDENTIFIER',
      41: 'VIEW',
      42: 'BASE_64',
      43: 'UNIT',
      44: 'DSSP',
      45: 'SCALE',
      46: 'ROTATE',
      48: 'TRANSLATE',
      49: 'CENTER',
      52: 'LINE',
      54: 'LISTOBJ',
      55: 'REMOVEOBJ',
      56: 'URL',
      57: 'VIEW_KEY',
      58: 'SCREENSHOT',
      59: 'LOAD',
      61: 'FILE_KEY',
      62: 'ADD',
      64: 'REP',
      65: 'MODE',
      66: 'COLOR',
      70: 'DESC_KEY',
      71: '=',
      72: 'DESC_KEY_OPTS',
      74: 'DESC_KEY_AXES',
      77: 'HEX',
      78: 'BOOL',
      82: 'CLEAR',
      83: 'FILE_LIST',
      84: 'FILE_REGISTER',
      85: 'FILE_DELETE',
      86: 'PRESET_ADD',
      87: 'PRESET_DELETE',
      88: 'PRESET_UPDATE',
      89: 'PRESET_RENAME',
      90: 'PRESET_OPEN',
      91: 'CREATE_SCENARIO',
      92: 'RESET_SCENARIO',
      93: 'DELETE_SCENARIO',
      94: 'ADD_SCENARIO_ITEM',
      95: 'LIST_SCENARIO',
      96: 'PDB_KEY',
      97: 'DELAY_KEY',
      98: 'PRST_KEY',
      99: 'DESCRIPTION_KEY',
      101: '.',
      103: '/'
    },
    productions_: [
      0,
      [3, 2],
      [3, 1],
      [4, 1],
      [4, 1],
      [4, 2],
      [4, 1],
      [4, 2],
      [4, 1],
      [4, 1],
      [4, 2],
      [4, 2],
      [4, 3],
      [4, 3],
      [4, 1],
      [4, 1],
      [4, 1],
      [4, 1],
      [4, 2],
      [4, 1],
      [4, 1],
      [4, 2],
      [4, 2],
      [4, 2],
      [4, 2],
      [4, 1],
      [4, 2],
      [4, 2],
      [4, 2],
      [4, 4],
      [4, 2],
      [4, 6],
      [4, 2],
      [4, 1],
      [4, 1],
      [4, 1],
      [4, 2],
      [4, 2],
      [4, 1],
      [4, 2],
      [4, 1],
      [4, 2],
      [4, 2],
      [4, 2],
      [4, 1],
      [4, 2],
      [4, 1],
      [4, 1],
      [4, 3],
      [4, 3],
      [4, 4],
      [4, 4],
      [4, 1],
      [4, 2],
      [50, 1],
      [50, 2],
      [50, 2],
      [50, 3],
      [50, 3],
      [51, 1],
      [51, 2],
      [51, 3],
      [12, 2],
      [12, 2],
      [12, 2],
      [21, 1],
      [21, 2],
      [21, 2],
      [21, 3],
      [22, 2],
      [22, 3],
      [39, 2],
      [39, 3],
      [40, 2],
      [40, 3],
      [24, 1],
      [24, 1],
      [63, 1],
      [63, 2],
      [63, 3],
      [63, 4],
      [67, 1],
      [67, 1],
      [67, 2],
      [68, 3],
      [69, 3],
      [47, 1],
      [47, 2],
      [73, 2],
      [53, 1],
      [53, 2],
      [75, 3],
      [16, 1],
      [16, 1],
      [16, 1],
      [16, 1],
      [16, 1],
      [79, 1],
      [79, 1],
      [32, 1],
      [32, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [80, 1],
      [81, 1],
      [81, 1],
      [81, 1],
      [81, 1],
      [81, 1],
      [81, 1],
      [81, 1],
      [100, 1],
      [100, 1],
      [76, 1],
      [76, 3],
      [76, 3],
      [10, 1],
      [10, 1],
      [10, 3],
      [10, 3],
      [10, 3],
      [60, 1],
      [102, 1],
      [102, 3],
      [104, 1],
      [104, 1]
    ],
    performAction: function anonymous(
      yytext,
      yyleng,
      yylineno,
      yy,
      yystate /* action[1] */,
      $$ /* vstack */,
      _$ /* lstack */
    ) {
      /* this == yyval */

      var $0 = $$.length - 1
      switch (yystate) {
        case 1:
          return $$[$0 - 1]
          break
        case 3:
          this.$ = yy.miew.reset(false)
          yy.ClearContext()
          yy.miew.resetReps('empty')
          break
        case 4:
          this.$ = yy.miew.rebuild()
          break
        case 5:
          this.$ = yy.miew.rebuildAll()
          yy.miew.rebuild()
          break
        case 6:
          this.$ = yy.echo(yy.utils.help().toString())
          break
        case 7:
          this.$ = yy.echo(yy.utils.help($$[$0]).toString())
          break
        case 8:
          this.$ = yy.miew.motm()
          break
        case 10:
        case 11:
          this.$ = yy.utils.propagateProp($$[$0])
          yy.echo(yy.miew.get($$[$0]).toString())
          break
        case 12:
        case 13:
          this.$ = yy.miew.set(
            $$[$0 - 1],
            yy.utils.propagateProp($$[$0 - 1], $$[$0])
          )
          break
        case 14:
          this.$ = yy.miew.saveSettings()
          break
        case 15:
          this.$ = yy.miew.restoreSettings()
          break
        case 16:
          this.$ = yy.miew.resetSettings()
          break
        case 17:
          this.$ = yy.miew.resetReps()
          break
        case 18:
          this.$ = yy.miew.applyPreset($$[$0])
          break
        case 21:
          this.$ = yy.miew.repRemove($$[$0])
          yy.representations.remove($$[$0])
          break
        case 22:
          this.$ = yy.miew.repHide($$[$0])
          break
        case 23:
          this.$ = yy.miew.repHide($$[$0], false)
          break
        case 24:
          this.$ = yy.echo(
            yy.utils.listRep(yy.miew, yy.representations, $$[$0], '-e')
          )
          break
        case 25:
          this.$ = yy.echo(yy.utils.list(yy.miew, yy.representations))
          break
        case 26:
          this.$ = yy.echo(yy.utils.list(yy.miew, yy.representations, $$[$0]))
          break
        case 27:
          this.$ = yy.echo(yy.utils.listSelector(yy.miew, yy.Context))
          break
        case 28:
          this.$ = yy.miew.select(
            yy.utils.checkArg($$[$0 - 1].toLowerCase(), $$[$0], true)
          )
          break
        case 29:
          this.$ = yy.Context[$$[$0].toLowerCase()] = yy.utils.checkArg(
            $$[$0 - 3].toLowerCase(),
            $$[$0 - 2],
            true
          )
          yy.miew.select(yy.Context[$$[$0].toLowerCase()])
          break
        case 30:
          this.$ = yy.miew.rep(yy.miew.repCurrent(), {
            selector: yy.utils.checkArg($$[$0 - 1].toLowerCase(), $$[$0])
          })
          break
        case 31:
          this.$ = yy.Context[$$[$0].toLowerCase()] = yy.miew.within(
            yy.utils.checkArg('select', $$[$0 - 2], true),
            Number($$[$0 - 4])
          )
          break
        case 32:
          this.$ = yy.miew.rep(yy.miew.repCurrent(), {
            material: yy.utils.checkArg(
              $$[$0 - 1].toLowerCase(),
              $$[$0].toUpperCase()
            )
          })
          break
        case 35:
          this.$ = yy.echo(yy.miew.view())
          break
        case 36:
        case 37:
          this.$ = yy.miew.view($$[$0])
          break
        case 38:
          this.$ = yy.echo(yy.miew.changeUnit())
          break
        case 39:
          this.$ = yy.echo(yy.miew.changeUnit($$[$0]))
          break
        case 40:
          this.$ = yy.miew.dssp()
          break
        case 41:
          this.$ = yy.miew.scale($$[$0])
          break
        case 42:
          for (var i = 0, n = $$[$0].length; i < n; i++) {
            yy.miew.rotate(
              ($$[$0][i]['x'] * Math.PI) / 180.0,
              ($$[$0][i]['y'] * Math.PI) / 180.0,
              ($$[$0][i]['z'] * Math.PI) / 180.0
            )
          }
          break
        case 43:
          for (var i = 0, n = $$[$0].length; i < n; i++) {
            yy.miew.translate(
              $$[$0][i]['x'] || 0,
              $$[$0][i]['y'] || 0,
              $$[$0][i]['z'] || 0
            )
          }
          break
        case 44:
          this.$ = yy.miew.center()
          break
        case 45:
          this.$ = yy.miew.center($$[$0])
          break
        case 48:
        case 49:
          this.$ = yy.miew.addObject(
            { type: 'line', params: [$$[$0 - 1], $$[$0]] },
            true
          )
          break
        case 50:
        case 51:
          this.$ = yy.miew.addObject(
            {
              type: 'line',
              params: [$$[$0 - 2], $$[$0 - 1]],
              opts: $$[$0].toJSO(yy.utils, 'objects', 'line')
            },
            true
          )
          break
        case 52:
          this.$ = yy.echo(yy.utils.listObjs(yy.miew))
          break
        case 53:
          this.$ = yy.miew.removeObject($$[$0])
          break
        case 54:
          this.$ = yy.echo(yy.miew.getURL({ view: false, settings: false }))
          break
        case 55:
          this.$ = yy.echo(yy.miew.getURL({ view: false, settings: true }))
          break
        case 56:
          this.$ = yy.echo(yy.miew.getURL({ view: true, settings: false }))
          break
        case 57:
        case 58:
          this.$ = yy.echo(yy.miew.getURL({ view: true, settings: true }))
          break
        case 59:
          this.$ = yy.miew.screenshotSave()
          break
        case 60:
          this.$ = yy.miew.screenshotSave('', Number($$[$0]))
          break
        case 61:
          this.$ = yy.miew.screenshotSave(
            '',
            Number($$[$0 - 1]),
            Number($$[$0])
          )
          break
        case 62:
        case 63:
        case 64:
          this.$ = yy.utils.load(yy.miew, $$[$0])
          yy.representations.clear()
          break
        case 65:
          this.$ = yy.echo(yy.representations.add(yy.miew.repAdd()))
          break
        case 66:
          this.$ = yy.echo(yy.representations.add($$[$0], yy.miew.repAdd()))
          break
        case 67:
          this.$ = yy.echo(yy.representations.add(yy.miew.repAdd($$[$0])))
          break
        case 68:
          this.$ = yy.echo(
            yy.representations.add($$[$0 - 1], yy.miew.repAdd($$[$0]))
          )
          break
        case 69:
          this.$ = yy.miew.rep($$[$0])
          yy.miew.repCurrent($$[$0])
          break
        case 70:
          this.$ = yy.miew.rep($$[$0 - 1], $$[$0])
          yy.miew.repCurrent($$[$0 - 1])
          break
        case 71:
          this.$ = yy.miew.rep(yy.miew.repCurrent(), {
            mode: yy.utils.checkArg(
              $$[$0 - 1].toLowerCase(),
              $$[$0].toUpperCase()
            )
          })
          break
        case 72:
          this.$ = yy.miew.rep(yy.miew.repCurrent(), {
            mode: new Array(
              yy.utils.checkArg(
                $$[$0 - 2].toLowerCase(),
                $$[$0 - 1].toUpperCase()
              ),
              $$[$0].toJSO(yy.utils, $$[$0 - 2], $$[$0 - 1].toUpperCase())
            )
          })
          break
        case 73:
          this.$ = yy.miew.rep(yy.miew.repCurrent(), {
            colorer: yy.utils.checkArg(
              $$[$0 - 1].toLowerCase(),
              $$[$0].toUpperCase()
            )
          })
          break
        case 74:
          this.$ = yy.miew.rep(yy.miew.repCurrent(), {
            colorer: new Array(
              yy.utils.checkArg(
                $$[$0 - 2].toLowerCase(),
                $$[$0 - 1].toUpperCase()
              ),
              $$[$0].toJSO(yy.utils, $$[$0 - 2], $$[$0 - 1].toUpperCase())
            )
          })
          break
        case 75:
          this.$ = Number(yy.representations.get($$[$0]))
          break
        case 76:
        case 92:
          this.$ = Number($$[$0])
          break
        case 77:
          this.$ = $$[$0]
          break
        case 78:
          this.$ = yy.assign($$[$0 - 1], $$[$0])
          break
        case 79:
          this.$ = yy.assign($$[$0 - 2], $$[$0 - 1], $$[$0])
          break
        case 80:
          this.$ = yy.assign($$[$0 - 3], $$[$0 - 2], $$[$0 - 1], $$[$0])
          break
        case 81:
        case 82:
          this.$ = yy.CreateObjectPair($$[$0].key, $$[$0].val)
          break
        case 83:
          this.$ = yy.CreateObjectPair(
            $$[$0 - 1].key,
            new Array(
              $$[$0 - 1].val,
              $$[$0].toJSO(yy.utils, $$[$0 - 1].key, $$[$0 - 1].val)
            )
          )
          break
        case 84:
        case 85:
          this.$ = Object.create({
            key: yy.keyRemap($$[$0 - 2]),
            val: yy.utils.checkArg($$[$0 - 2], $$[$0])
          })
          break
        case 86:
          this.$ = [$$[$0]]
          break
        case 87:
          this.$ = $$[$0 - 1].concat($$[$0])
          break
        case 88:
          this.$ = yy.CreateObjectPair($$[$0 - 1].toLowerCase(), Number($$[$0]))
          break
        case 89:
          this.$ = new yy.ArgList($$[$0])
          break
        case 90:
          this.$ = $$[$0 - 1].append($$[$0])
          break
        case 91:
          this.$ = new yy.Arg($$[$0 - 2], $$[$0])
          break
        case 93:
          this.$ = parseInt($$[$0])
          break
        case 94:
          this.$ = JSON.parse($$[$0])
          break
        case 95:
        case 96:
          this.$ = String($$[$0])
          break
        case 157:
        case 158:
        case 161:
        case 162:
        case 163:
          this.$ = $$[$0 - 2] + $$[$0 - 1] + $$[$0] //cause of could be color word in path;
          break
        case 166:
          this.$ = $$[$0 - 2] = $$[$0 - 2] + $$[$0 - 1] + $$[$0]
          break
      }
    },
    table: [
      {
        3: 1,
        4: 2,
        5: [1, 3],
        6: [1, 4],
        7: [1, 5],
        9: [1, 6],
        11: [1, 7],
        12: 8,
        13: [1, 9],
        15: [1, 10],
        17: [1, 11],
        18: [1, 12],
        19: [1, 13],
        20: [1, 14],
        21: 15,
        22: 16,
        23: [1, 17],
        25: [1, 18],
        26: [1, 19],
        27: [1, 20],
        30: [1, 21],
        33: [1, 22],
        34: [1, 23],
        37: [1, 24],
        39: 25,
        40: 26,
        41: [1, 27],
        43: [1, 28],
        44: [1, 29],
        45: [1, 30],
        46: [1, 31],
        48: [1, 32],
        49: [1, 33],
        50: 34,
        51: 35,
        52: [1, 36],
        54: [1, 37],
        55: [1, 38],
        56: [1, 44],
        58: [1, 45],
        59: [1, 39],
        62: [1, 40],
        64: [1, 41],
        65: [1, 42],
        66: [1, 43]
      },
      { 1: [3] },
      { 5: [1, 46] },
      { 1: [2, 2] },
      { 5: [2, 3] },
      { 5: [2, 4], 8: [1, 47] },
      {
        5: [2, 6],
        6: $V0,
        7: $V1,
        9: $V2,
        10: 48,
        13: $V3,
        15: $V4,
        17: $V5,
        18: $V6,
        19: $V7,
        20: $V8,
        23: $V9,
        25: $Va,
        26: $Vb,
        27: $Vc,
        30: $Vd,
        33: $Ve,
        34: $Vf,
        37: $Vg,
        38: $Vh,
        41: $Vi,
        43: $Vj,
        45: $Vk,
        46: $Vl,
        49: $Vm,
        52: $Vn,
        54: $Vo,
        55: $Vp,
        56: $Vq,
        58: $Vr,
        59: $Vs,
        62: $Vt,
        64: $Vu,
        65: $Vv,
        66: $Vw,
        70: $Vx,
        72: $Vy,
        74: $Vz,
        79: 49,
        80: 52,
        81: 50,
        82: $VA,
        83: $VB,
        84: $VC,
        85: $VD,
        86: $VE,
        87: $VF,
        88: $VG,
        89: $VH,
        90: $VI,
        91: $VJ,
        92: $VK,
        93: $VL,
        94: $VM,
        95: $VN,
        96: $VO,
        97: $VP,
        98: $VQ,
        99: $VR
      },
      { 5: [2, 8] },
      { 5: [2, 9] },
      {
        6: $V0,
        7: $V1,
        9: $V2,
        10: 106,
        13: $V3,
        14: [1, 107],
        15: $V4,
        17: $V5,
        18: $V6,
        19: $V7,
        20: $V8,
        23: $V9,
        25: $Va,
        26: $Vb,
        27: $Vc,
        30: $Vd,
        33: $Ve,
        34: $Vf,
        37: $Vg,
        38: $Vh,
        41: $Vi,
        43: $Vj,
        45: $Vk,
        46: $Vl,
        49: $Vm,
        52: $Vn,
        54: $Vo,
        55: $Vp,
        56: $Vq,
        58: $Vr,
        59: $Vs,
        62: $Vt,
        64: $Vu,
        65: $Vv,
        66: $Vw,
        70: $Vx,
        72: $Vy,
        74: $Vz,
        79: 49,
        80: 52,
        81: 50,
        82: $VA,
        83: $VB,
        84: $VC,
        85: $VD,
        86: $VE,
        87: $VF,
        88: $VG,
        89: $VH,
        90: $VI,
        91: $VJ,
        92: $VK,
        93: $VL,
        94: $VM,
        95: $VN,
        96: $VO,
        97: $VP,
        98: $VQ,
        99: $VR
      },
      {
        6: $V0,
        7: $V1,
        9: $V2,
        10: 108,
        13: $V3,
        14: [1, 109],
        15: $V4,
        17: $V5,
        18: $V6,
        19: $V7,
        20: $V8,
        23: $V9,
        25: $Va,
        26: $Vb,
        27: $Vc,
        30: $Vd,
        33: $Ve,
        34: $Vf,
        37: $Vg,
        38: $Vh,
        41: $Vi,
        43: $Vj,
        45: $Vk,
        46: $Vl,
        49: $Vm,
        52: $Vn,
        54: $Vo,
        55: $Vp,
        56: $Vq,
        58: $Vr,
        59: $Vs,
        62: $Vt,
        64: $Vu,
        65: $Vv,
        66: $Vw,
        70: $Vx,
        72: $Vy,
        74: $Vz,
        79: 49,
        80: 52,
        81: 50,
        82: $VA,
        83: $VB,
        84: $VC,
        85: $VD,
        86: $VE,
        87: $VF,
        88: $VG,
        89: $VH,
        90: $VI,
        91: $VJ,
        92: $VK,
        93: $VL,
        94: $VM,
        95: $VN,
        96: $VO,
        97: $VP,
        98: $VQ,
        99: $VR
      },
      { 5: [2, 14] },
      { 5: [2, 15] },
      { 5: [2, 16] },
      { 5: [2, 17], 14: $VS, 16: 110, 35: $VT, 38: $VU, 77: $VV, 78: $VW },
      { 5: [2, 19] },
      { 5: [2, 20] },
      { 24: 116, 35: $VX, 38: $VY },
      { 24: 119, 35: $VX, 38: $VY },
      { 24: 120, 35: $VX, 38: $VY },
      { 5: [2, 25], 24: 121, 28: [1, 122], 29: [1, 123], 35: $VX, 38: $VY },
      { 14: [1, 124] },
      { 14: [1, 125] },
      { 35: [1, 126] },
      { 38: [1, 127] },
      { 5: [2, 33] },
      { 5: [2, 34] },
      { 5: [2, 35], 14: [1, 128], 42: [1, 129] },
      { 5: [2, 38], 35: [1, 130] },
      { 5: [2, 40] },
      { 35: [1, 131] },
      { 47: 132, 73: 133, 74: $VZ },
      { 47: 135, 73: 133, 74: $VZ },
      { 5: [2, 44], 14: [1, 136] },
      { 5: [2, 46] },
      { 5: [2, 47] },
      {
        6: $V0,
        7: $V1,
        9: $V2,
        10: 138,
        13: $V3,
        14: [1, 137],
        15: $V4,
        17: $V5,
        18: $V6,
        19: $V7,
        20: $V8,
        23: $V9,
        25: $Va,
        26: $Vb,
        27: $Vc,
        30: $Vd,
        33: $Ve,
        34: $Vf,
        37: $Vg,
        38: $Vh,
        41: $Vi,
        43: $Vj,
        45: $Vk,
        46: $Vl,
        49: $Vm,
        52: $Vn,
        54: $Vo,
        55: $Vp,
        56: $Vq,
        58: $Vr,
        59: $Vs,
        62: $Vt,
        64: $Vu,
        65: $Vv,
        66: $Vw,
        70: $Vx,
        72: $Vy,
        74: $Vz,
        79: 49,
        80: 52,
        81: 50,
        82: $VA,
        83: $VB,
        84: $VC,
        85: $VD,
        86: $VE,
        87: $VF,
        88: $VG,
        89: $VH,
        90: $VI,
        91: $VJ,
        92: $VK,
        93: $VL,
        94: $VM,
        95: $VN,
        96: $VO,
        97: $VP,
        98: $VQ,
        99: $VR
      },
      { 5: [2, 52] },
      { 35: [1, 139] },
      { 14: [1, 143], 38: [1, 141], 60: 140, 61: [1, 142] },
      {
        5: [2, 65],
        38: [1, 144],
        63: 145,
        67: 146,
        68: 147,
        69: 148,
        70: $V_,
        72: $V$
      },
      { 24: 151, 35: $VX, 38: $VY },
      { 38: [1, 152] },
      { 38: [1, 153] },
      { 5: [2, 54], 29: [1, 154], 57: [1, 155] },
      { 5: [2, 59], 35: [1, 156] },
      { 1: [2, 1] },
      { 5: [2, 5] },
      { 5: [2, 7], 101: $V01 },
      o($V11, [2, 159]),
      o($V11, [2, 160]),
      o($V21, [2, 97]),
      o($V21, [2, 98]),
      o($V11, [2, 147]),
      o($V11, [2, 148]),
      o($V11, [2, 149]),
      o($V11, [2, 150]),
      o($V11, [2, 151]),
      o($V11, [2, 152]),
      o($V11, [2, 153]),
      o($V21, [2, 101]),
      o($V21, [2, 102]),
      o($V21, [2, 103]),
      o($V21, [2, 104]),
      o($V21, [2, 105]),
      o($V21, [2, 106]),
      o($V21, [2, 107]),
      o($V21, [2, 108]),
      o($V21, [2, 109]),
      o($V21, [2, 110]),
      o($V21, [2, 111]),
      o($V21, [2, 112]),
      o($V21, [2, 113]),
      o($V21, [2, 114]),
      o($V21, [2, 115]),
      o($V21, [2, 116]),
      o($V21, [2, 117]),
      o($V21, [2, 118]),
      o($V21, [2, 119]),
      o($V21, [2, 120]),
      o($V21, [2, 121]),
      o($V21, [2, 122]),
      o($V21, [2, 123]),
      o($V21, [2, 124]),
      o($V21, [2, 125]),
      o($V21, [2, 126]),
      o($V21, [2, 127]),
      o($V21, [2, 128]),
      o($V21, [2, 129]),
      o($V21, [2, 130]),
      o($V21, [2, 131]),
      o($V21, [2, 132]),
      o($V21, [2, 133]),
      o($V21, [2, 134]),
      o($V21, [2, 135]),
      o($V21, [2, 136]),
      o($V21, [2, 137]),
      o($V21, [2, 138]),
      o($V21, [2, 139]),
      o($V21, [2, 140]),
      o($V21, [2, 141]),
      o($V21, [2, 142]),
      o($V21, [2, 143]),
      o($V21, [2, 144]),
      o($V21, [2, 145]),
      o($V21, [2, 146]),
      { 5: [2, 10], 101: $V01 },
      { 5: [2, 11] },
      { 14: $VS, 16: 158, 35: $VT, 38: $VU, 77: $VV, 78: $VW, 101: $V01 },
      { 14: $VS, 16: 159, 35: $VT, 38: $VU, 77: $VV, 78: $VW },
      { 5: [2, 18] },
      o($V31, [2, 92]),
      o($V31, [2, 93]),
      o($V31, [2, 94]),
      o($V31, [2, 95]),
      o($V31, [2, 96]),
      { 5: [2, 21] },
      o($V41, [2, 75]),
      o($V41, [2, 76]),
      { 5: [2, 22] },
      { 5: [2, 23] },
      { 5: [2, 24] },
      { 5: [2, 26] },
      { 5: [2, 27] },
      { 5: [2, 28], 31: [1, 160] },
      { 5: [2, 30] },
      { 36: [1, 161] },
      { 5: [2, 32] },
      { 5: [2, 36] },
      { 5: [2, 37] },
      { 5: [2, 39] },
      { 5: [2, 41] },
      { 5: [2, 42], 73: 162, 74: $VZ },
      o($V51, [2, 86]),
      { 35: [1, 163] },
      { 5: [2, 43], 73: 162, 74: $VZ },
      { 5: [2, 45] },
      { 14: [1, 164] },
      {
        6: $V0,
        7: $V1,
        9: $V2,
        10: 165,
        13: $V3,
        15: $V4,
        17: $V5,
        18: $V6,
        19: $V7,
        20: $V8,
        23: $V9,
        25: $Va,
        26: $Vb,
        27: $Vc,
        30: $Vd,
        33: $Ve,
        34: $Vf,
        37: $Vg,
        38: $Vh,
        41: $Vi,
        43: $Vj,
        45: $Vk,
        46: $Vl,
        49: $Vm,
        52: $Vn,
        54: $Vo,
        55: $Vp,
        56: $Vq,
        58: $Vr,
        59: $Vs,
        62: $Vt,
        64: $Vu,
        65: $Vv,
        66: $Vw,
        70: $Vx,
        72: $Vy,
        74: $Vz,
        79: 49,
        80: 52,
        81: 50,
        82: $VA,
        83: $VB,
        84: $VC,
        85: $VD,
        86: $VE,
        87: $VF,
        88: $VG,
        89: $VH,
        90: $VI,
        91: $VJ,
        92: $VK,
        93: $VL,
        94: $VM,
        95: $VN,
        96: $VO,
        97: $VP,
        98: $VQ,
        99: $VR,
        101: $V01
      },
      { 5: [2, 53] },
      { 5: [2, 62] },
      { 5: [2, 63] },
      { 5: [2, 64] },
      { 5: [2, 164] },
      { 5: [2, 66], 63: 166, 67: 146, 68: 147, 69: 148, 70: $V_, 72: $V$ },
      { 5: [2, 67] },
      { 5: [2, 77], 67: 167, 68: 147, 69: 148, 70: $V_, 72: $V$ },
      o($V41, [2, 81]),
      o($V41, [2, 82], {
        80: 52,
        53: 168,
        75: 169,
        76: 170,
        79: 171,
        6: $V0,
        7: $V1,
        9: $V2,
        13: $V3,
        15: $V4,
        17: $V5,
        18: $V6,
        19: $V7,
        20: $V8,
        23: $V9,
        25: $Va,
        26: $Vb,
        27: $Vc,
        30: $Vd,
        33: $Ve,
        34: $Vf,
        37: $Vg,
        38: $Vh,
        41: $Vi,
        43: $Vj,
        45: $Vk,
        46: $Vl,
        49: $Vm,
        52: $Vn,
        54: $Vo,
        55: $Vp,
        56: $Vq,
        58: $Vr,
        59: $Vs,
        62: $Vt,
        64: $Vu,
        65: $Vv,
        66: $Vw,
        82: $VA,
        83: $VB,
        84: $VC,
        85: $VD,
        86: $VE,
        87: $VF,
        88: $VG,
        89: $VH,
        90: $VI,
        91: $VJ,
        92: $VK,
        93: $VL,
        94: $VM,
        95: $VN
      }),
      { 71: [1, 172] },
      { 71: [1, 173] },
      { 5: [2, 69], 63: 174, 67: 146, 68: 147, 69: 148, 70: $V_, 72: $V$ },
      {
        5: [2, 71],
        6: $V0,
        7: $V1,
        9: $V2,
        13: $V3,
        15: $V4,
        17: $V5,
        18: $V6,
        19: $V7,
        20: $V8,
        23: $V9,
        25: $Va,
        26: $Vb,
        27: $Vc,
        30: $Vd,
        33: $Ve,
        34: $Vf,
        37: $Vg,
        38: $Vh,
        41: $Vi,
        43: $Vj,
        45: $Vk,
        46: $Vl,
        49: $Vm,
        52: $Vn,
        53: 175,
        54: $Vo,
        55: $Vp,
        56: $Vq,
        58: $Vr,
        59: $Vs,
        62: $Vt,
        64: $Vu,
        65: $Vv,
        66: $Vw,
        75: 169,
        76: 170,
        79: 171,
        80: 52,
        82: $VA,
        83: $VB,
        84: $VC,
        85: $VD,
        86: $VE,
        87: $VF,
        88: $VG,
        89: $VH,
        90: $VI,
        91: $VJ,
        92: $VK,
        93: $VL,
        94: $VM,
        95: $VN
      },
      {
        5: [2, 73],
        6: $V0,
        7: $V1,
        9: $V2,
        13: $V3,
        15: $V4,
        17: $V5,
        18: $V6,
        19: $V7,
        20: $V8,
        23: $V9,
        25: $Va,
        26: $Vb,
        27: $Vc,
        30: $Vd,
        33: $Ve,
        34: $Vf,
        37: $Vg,
        38: $Vh,
        41: $Vi,
        43: $Vj,
        45: $Vk,
        46: $Vl,
        49: $Vm,
        52: $Vn,
        53: 176,
        54: $Vo,
        55: $Vp,
        56: $Vq,
        58: $Vr,
        59: $Vs,
        62: $Vt,
        64: $Vu,
        65: $Vv,
        66: $Vw,
        75: 169,
        76: 170,
        79: 171,
        80: 52,
        82: $VA,
        83: $VB,
        84: $VC,
        85: $VD,
        86: $VE,
        87: $VF,
        88: $VG,
        89: $VH,
        90: $VI,
        91: $VJ,
        92: $VK,
        93: $VL,
        94: $VM,
        95: $VN
      },
      { 5: [2, 55], 57: [1, 177] },
      { 5: [2, 56], 29: [1, 178] },
      { 5: [2, 60], 35: [1, 179] },
      {
        6: $V0,
        7: $V1,
        9: $V2,
        13: $V3,
        15: $V4,
        17: $V5,
        18: $V6,
        19: $V7,
        20: $V8,
        23: $V9,
        25: $Va,
        26: $Vb,
        27: $Vc,
        30: $Vd,
        33: $Ve,
        34: $Vf,
        35: [1, 181],
        37: $Vg,
        38: $Vh,
        41: $Vi,
        43: $Vj,
        45: $Vk,
        46: $Vl,
        49: $Vm,
        52: $Vn,
        54: $Vo,
        55: $Vp,
        56: $Vq,
        58: $Vr,
        59: $Vs,
        62: $Vt,
        64: $Vu,
        65: $Vv,
        66: $Vw,
        70: $Vx,
        72: $Vy,
        74: $Vz,
        79: 180,
        80: 52,
        81: 182,
        82: $VA,
        83: $VB,
        84: $VC,
        85: $VD,
        86: $VE,
        87: $VF,
        88: $VG,
        89: $VH,
        90: $VI,
        91: $VJ,
        92: $VK,
        93: $VL,
        94: $VM,
        95: $VN,
        96: $VO,
        97: $VP,
        98: $VQ,
        99: $VR
      },
      { 5: [2, 12] },
      { 5: [2, 13] },
      {
        6: $V0,
        7: $V1,
        9: $V2,
        13: $V3,
        15: $V4,
        17: $V5,
        18: $V6,
        19: $V7,
        20: $V8,
        23: $V9,
        25: $Va,
        26: $Vb,
        27: $Vc,
        30: $Vd,
        32: 183,
        33: $Ve,
        34: $Vf,
        37: $Vg,
        38: $Vh,
        41: $Vi,
        43: $Vj,
        45: $Vk,
        46: $Vl,
        49: $Vm,
        52: $Vn,
        54: $Vo,
        55: $Vp,
        56: $Vq,
        58: $Vr,
        59: $Vs,
        62: $Vt,
        64: $Vu,
        65: $Vv,
        66: $Vw,
        70: $Vx,
        72: $Vy,
        74: $Vz,
        79: 184,
        80: 52,
        81: 185,
        82: $VA,
        83: $VB,
        84: $VC,
        85: $VD,
        86: $VE,
        87: $VF,
        88: $VG,
        89: $VH,
        90: $VI,
        91: $VJ,
        92: $VK,
        93: $VL,
        94: $VM,
        95: $VN,
        96: $VO,
        97: $VP,
        98: $VQ,
        99: $VR
      },
      { 14: [1, 186] },
      o($V51, [2, 87]),
      o($V51, [2, 88]),
      {
        5: [2, 48],
        6: $V0,
        7: $V1,
        9: $V2,
        13: $V3,
        15: $V4,
        17: $V5,
        18: $V6,
        19: $V7,
        20: $V8,
        23: $V9,
        25: $Va,
        26: $Vb,
        27: $Vc,
        30: $Vd,
        33: $Ve,
        34: $Vf,
        37: $Vg,
        38: $Vh,
        41: $Vi,
        43: $Vj,
        45: $Vk,
        46: $Vl,
        49: $Vm,
        52: $Vn,
        53: 187,
        54: $Vo,
        55: $Vp,
        56: $Vq,
        58: $Vr,
        59: $Vs,
        62: $Vt,
        64: $Vu,
        65: $Vv,
        66: $Vw,
        75: 169,
        76: 170,
        79: 171,
        80: 52,
        82: $VA,
        83: $VB,
        84: $VC,
        85: $VD,
        86: $VE,
        87: $VF,
        88: $VG,
        89: $VH,
        90: $VI,
        91: $VJ,
        92: $VK,
        93: $VL,
        94: $VM,
        95: $VN
      },
      {
        5: [2, 49],
        6: $V0,
        7: $V1,
        9: $V2,
        13: $V3,
        15: $V4,
        17: $V5,
        18: $V6,
        19: $V7,
        20: $V8,
        23: $V9,
        25: $Va,
        26: $Vb,
        27: $Vc,
        30: $Vd,
        33: $Ve,
        34: $Vf,
        37: $Vg,
        38: $Vh,
        41: $Vi,
        43: $Vj,
        45: $Vk,
        46: $Vl,
        49: $Vm,
        52: $Vn,
        53: 188,
        54: $Vo,
        55: $Vp,
        56: $Vq,
        58: $Vr,
        59: $Vs,
        62: $Vt,
        64: $Vu,
        65: $Vv,
        66: $Vw,
        75: 169,
        76: 170,
        79: 171,
        80: 52,
        82: $VA,
        83: $VB,
        84: $VC,
        85: $VD,
        86: $VE,
        87: $VF,
        88: $VG,
        89: $VH,
        90: $VI,
        91: $VJ,
        92: $VK,
        93: $VL,
        94: $VM,
        95: $VN,
        101: $V01
      },
      { 5: [2, 68] },
      { 5: [2, 78], 67: 189, 68: 147, 69: 148, 70: $V_, 72: $V$ },
      o($V41, [2, 83], {
        80: 52,
        76: 170,
        79: 171,
        75: 190,
        6: $V0,
        7: $V1,
        9: $V2,
        13: $V3,
        15: $V4,
        17: $V5,
        18: $V6,
        19: $V7,
        20: $V8,
        23: $V9,
        25: $Va,
        26: $Vb,
        27: $Vc,
        30: $Vd,
        33: $Ve,
        34: $Vf,
        37: $Vg,
        38: $Vh,
        41: $Vi,
        43: $Vj,
        45: $Vk,
        46: $Vl,
        49: $Vm,
        52: $Vn,
        54: $Vo,
        55: $Vp,
        56: $Vq,
        58: $Vr,
        59: $Vs,
        62: $Vt,
        64: $Vu,
        65: $Vv,
        66: $Vw,
        82: $VA,
        83: $VB,
        84: $VC,
        85: $VD,
        86: $VE,
        87: $VF,
        88: $VG,
        89: $VH,
        90: $VI,
        91: $VJ,
        92: $VK,
        93: $VL,
        94: $VM,
        95: $VN
      }),
      o($V31, [2, 89]),
      { 71: [1, 191], 101: [1, 192] },
      o($V61, [2, 156]),
      { 14: $VS, 16: 193, 35: $VT, 38: $VU, 77: $VV, 78: $VW },
      { 14: $VS, 16: 194, 35: $VT, 38: $VU, 77: $VV, 78: $VW },
      { 5: [2, 70] },
      {
        5: [2, 72],
        6: $V0,
        7: $V1,
        9: $V2,
        13: $V3,
        15: $V4,
        17: $V5,
        18: $V6,
        19: $V7,
        20: $V8,
        23: $V9,
        25: $Va,
        26: $Vb,
        27: $Vc,
        30: $Vd,
        33: $Ve,
        34: $Vf,
        37: $Vg,
        38: $Vh,
        41: $Vi,
        43: $Vj,
        45: $Vk,
        46: $Vl,
        49: $Vm,
        52: $Vn,
        54: $Vo,
        55: $Vp,
        56: $Vq,
        58: $Vr,
        59: $Vs,
        62: $Vt,
        64: $Vu,
        65: $Vv,
        66: $Vw,
        75: 190,
        76: 170,
        79: 171,
        80: 52,
        82: $VA,
        83: $VB,
        84: $VC,
        85: $VD,
        86: $VE,
        87: $VF,
        88: $VG,
        89: $VH,
        90: $VI,
        91: $VJ,
        92: $VK,
        93: $VL,
        94: $VM,
        95: $VN
      },
      {
        5: [2, 74],
        6: $V0,
        7: $V1,
        9: $V2,
        13: $V3,
        15: $V4,
        17: $V5,
        18: $V6,
        19: $V7,
        20: $V8,
        23: $V9,
        25: $Va,
        26: $Vb,
        27: $Vc,
        30: $Vd,
        33: $Ve,
        34: $Vf,
        37: $Vg,
        38: $Vh,
        41: $Vi,
        43: $Vj,
        45: $Vk,
        46: $Vl,
        49: $Vm,
        52: $Vn,
        54: $Vo,
        55: $Vp,
        56: $Vq,
        58: $Vr,
        59: $Vs,
        62: $Vt,
        64: $Vu,
        65: $Vv,
        66: $Vw,
        75: 190,
        76: 170,
        79: 171,
        80: 52,
        82: $VA,
        83: $VB,
        84: $VC,
        85: $VD,
        86: $VE,
        87: $VF,
        88: $VG,
        89: $VH,
        90: $VI,
        91: $VJ,
        92: $VK,
        93: $VL,
        94: $VM,
        95: $VN
      },
      { 5: [2, 57] },
      { 5: [2, 58] },
      { 5: [2, 61] },
      o($V11, [2, 161]),
      o($V11, [2, 162]),
      o($V11, [2, 163]),
      { 5: [2, 29] },
      { 5: [2, 99] },
      { 5: [2, 100] },
      { 31: [1, 195] },
      {
        5: [2, 50],
        6: $V0,
        7: $V1,
        9: $V2,
        13: $V3,
        15: $V4,
        17: $V5,
        18: $V6,
        19: $V7,
        20: $V8,
        23: $V9,
        25: $Va,
        26: $Vb,
        27: $Vc,
        30: $Vd,
        33: $Ve,
        34: $Vf,
        37: $Vg,
        38: $Vh,
        41: $Vi,
        43: $Vj,
        45: $Vk,
        46: $Vl,
        49: $Vm,
        52: $Vn,
        54: $Vo,
        55: $Vp,
        56: $Vq,
        58: $Vr,
        59: $Vs,
        62: $Vt,
        64: $Vu,
        65: $Vv,
        66: $Vw,
        75: 190,
        76: 170,
        79: 171,
        80: 52,
        82: $VA,
        83: $VB,
        84: $VC,
        85: $VD,
        86: $VE,
        87: $VF,
        88: $VG,
        89: $VH,
        90: $VI,
        91: $VJ,
        92: $VK,
        93: $VL,
        94: $VM,
        95: $VN
      },
      {
        5: [2, 51],
        6: $V0,
        7: $V1,
        9: $V2,
        13: $V3,
        15: $V4,
        17: $V5,
        18: $V6,
        19: $V7,
        20: $V8,
        23: $V9,
        25: $Va,
        26: $Vb,
        27: $Vc,
        30: $Vd,
        33: $Ve,
        34: $Vf,
        37: $Vg,
        38: $Vh,
        41: $Vi,
        43: $Vj,
        45: $Vk,
        46: $Vl,
        49: $Vm,
        52: $Vn,
        54: $Vo,
        55: $Vp,
        56: $Vq,
        58: $Vr,
        59: $Vs,
        62: $Vt,
        64: $Vu,
        65: $Vv,
        66: $Vw,
        75: 190,
        76: 170,
        79: 171,
        80: 52,
        82: $VA,
        83: $VB,
        84: $VC,
        85: $VD,
        86: $VE,
        87: $VF,
        88: $VG,
        89: $VH,
        90: $VI,
        91: $VJ,
        92: $VK,
        93: $VL,
        94: $VM,
        95: $VN
      },
      { 5: [2, 79], 67: 196, 68: 147, 69: 148, 70: $V_, 72: $V$ },
      o($V31, [2, 90]),
      { 14: $VS, 16: 197, 35: $VT, 38: $VU, 77: $VV, 78: $VW },
      {
        6: $V0,
        7: $V1,
        9: $V2,
        13: $V3,
        15: $V4,
        17: $V5,
        18: $V6,
        19: $V7,
        20: $V8,
        23: $V9,
        25: $Va,
        26: $Vb,
        27: $Vc,
        30: $Vd,
        33: $Ve,
        34: $Vf,
        35: [1, 199],
        37: $Vg,
        38: $Vh,
        41: $Vi,
        43: $Vj,
        45: $Vk,
        46: $Vl,
        49: $Vm,
        52: $Vn,
        54: $Vo,
        55: $Vp,
        56: $Vq,
        58: $Vr,
        59: $Vs,
        62: $Vt,
        64: $Vu,
        65: $Vv,
        66: $Vw,
        79: 198,
        80: 52,
        82: $VA,
        83: $VB,
        84: $VC,
        85: $VD,
        86: $VE,
        87: $VF,
        88: $VG,
        89: $VH,
        90: $VI,
        91: $VJ,
        92: $VK,
        93: $VL,
        94: $VM,
        95: $VN
      },
      o($V41, [2, 84]),
      o($V31, [2, 85]),
      {
        6: $V0,
        7: $V1,
        9: $V2,
        13: $V3,
        15: $V4,
        17: $V5,
        18: $V6,
        19: $V7,
        20: $V8,
        23: $V9,
        25: $Va,
        26: $Vb,
        27: $Vc,
        30: $Vd,
        32: 200,
        33: $Ve,
        34: $Vf,
        37: $Vg,
        38: $Vh,
        41: $Vi,
        43: $Vj,
        45: $Vk,
        46: $Vl,
        49: $Vm,
        52: $Vn,
        54: $Vo,
        55: $Vp,
        56: $Vq,
        58: $Vr,
        59: $Vs,
        62: $Vt,
        64: $Vu,
        65: $Vv,
        66: $Vw,
        70: $Vx,
        72: $Vy,
        74: $Vz,
        79: 184,
        80: 52,
        81: 185,
        82: $VA,
        83: $VB,
        84: $VC,
        85: $VD,
        86: $VE,
        87: $VF,
        88: $VG,
        89: $VH,
        90: $VI,
        91: $VJ,
        92: $VK,
        93: $VL,
        94: $VM,
        95: $VN,
        96: $VO,
        97: $VP,
        98: $VQ,
        99: $VR
      },
      { 5: [2, 80] },
      o($V31, [2, 91]),
      o($V61, [2, 157]),
      o($V61, [2, 158]),
      { 5: [2, 31] }
    ],
    defaultActions: {
      3: [2, 2],
      4: [2, 3],
      7: [2, 8],
      8: [2, 9],
      11: [2, 14],
      12: [2, 15],
      13: [2, 16],
      15: [2, 19],
      16: [2, 20],
      25: [2, 33],
      26: [2, 34],
      29: [2, 40],
      34: [2, 46],
      35: [2, 47],
      37: [2, 52],
      46: [2, 1],
      47: [2, 5],
      107: [2, 11],
      110: [2, 18],
      116: [2, 21],
      119: [2, 22],
      120: [2, 23],
      121: [2, 24],
      122: [2, 26],
      123: [2, 27],
      125: [2, 30],
      127: [2, 32],
      128: [2, 36],
      129: [2, 37],
      130: [2, 39],
      131: [2, 41],
      136: [2, 45],
      139: [2, 53],
      140: [2, 62],
      141: [2, 63],
      142: [2, 64],
      143: [2, 164],
      145: [2, 67],
      158: [2, 12],
      159: [2, 13],
      166: [2, 68],
      174: [2, 70],
      177: [2, 57],
      178: [2, 58],
      179: [2, 61],
      183: [2, 29],
      184: [2, 99],
      185: [2, 100],
      196: [2, 80],
      200: [2, 31]
    },
    parseError: function parseError(str, hash) {
      if (hash.recoverable) {
        this.trace(str)
      } else {
        var error = new Error(str)
        error.hash = hash
        throw error
      }
    },
    parse: function parse(input) {
      var self = this,
        stack = [0],
        tstack = [],
        vstack = [null],
        lstack = [],
        table = this.table,
        yytext = '',
        yylineno = 0,
        yyleng = 0,
        recovering = 0,
        TERROR = 2,
        EOF = 1
      var args = lstack.slice.call(arguments, 1)
      var lexer = Object.create(this.lexer)
      var sharedState = { yy: {} }
      for (var k in this.yy) {
        if (Object.prototype.hasOwnProperty.call(this.yy, k)) {
          sharedState.yy[k] = this.yy[k]
        }
      }
      lexer.setInput(input, sharedState.yy)
      sharedState.yy.lexer = lexer
      sharedState.yy.parser = this
      if (typeof lexer.yylloc == 'undefined') {
        lexer.yylloc = {}
      }
      var yyloc = lexer.yylloc
      lstack.push(yyloc)
      var ranges = lexer.options && lexer.options.ranges
      if (typeof sharedState.yy.parseError === 'function') {
        this.parseError = sharedState.yy.parseError
      } else {
        this.parseError = Object.getPrototypeOf(this).parseError
      }
      function popStack(n) {
        stack.length = stack.length - 2 * n
        vstack.length = vstack.length - n
        lstack.length = lstack.length - n
      }
      function lex() {
        var token
        token = tstack.pop() || lexer.lex() || EOF
        if (typeof token !== 'number') {
          if (token instanceof Array) {
            tstack = token
            token = tstack.pop()
          }
          token = self.symbols_[token] || token
        }
        return token
      }
      var symbol,
        preErrorSymbol,
        state,
        action,
        a,
        r,
        yyval = {},
        p,
        len,
        newState,
        expected
      while (true) {
        state = stack[stack.length - 1]
        if (this.defaultActions[state]) {
          action = this.defaultActions[state]
        } else {
          if (symbol === null || typeof symbol == 'undefined') {
            symbol = lex()
          }
          action = table[state] && table[state][symbol]
        }
        if (typeof action === 'undefined' || !action.length || !action[0]) {
          var errStr = ''
          expected = []
          for (p in table[state]) {
            if (this.terminals_[p] && p > TERROR) {
              expected.push("'" + this.terminals_[p] + "'")
            }
          }
          if (lexer.showPosition) {
            errStr =
              'Parse error on line ' +
              (yylineno + 1) +
              ':\n' +
              lexer.showPosition() +
              '\nExpecting ' +
              expected.join(', ') +
              ", got '" +
              (this.terminals_[symbol] || symbol) +
              "'"
          } else {
            errStr =
              'Parse error on line ' +
              (yylineno + 1) +
              ': Unexpected ' +
              (symbol == EOF
                ? 'end of input'
                : "'" + (this.terminals_[symbol] || symbol) + "'")
          }
          this.parseError(errStr, {
            text: lexer.match,
            token: this.terminals_[symbol] || symbol,
            line: lexer.yylineno,
            loc: yyloc,
            expected: expected
          })
        }
        if (action[0] instanceof Array && action.length > 1) {
          throw new Error(
            'Parse Error: multiple actions possible at state: ' +
              state +
              ', token: ' +
              symbol
          )
        }
        switch (action[0]) {
          case 1:
            stack.push(symbol)
            vstack.push(lexer.yytext)
            lstack.push(lexer.yylloc)
            stack.push(action[1])
            symbol = null
            if (!preErrorSymbol) {
              yyleng = lexer.yyleng
              yytext = lexer.yytext
              yylineno = lexer.yylineno
              yyloc = lexer.yylloc
              if (recovering > 0) {
                recovering--
              }
            } else {
              symbol = preErrorSymbol
              preErrorSymbol = null
            }
            break
          case 2:
            len = this.productions_[action[1]][1]
            yyval.$ = vstack[vstack.length - len]
            yyval._$ = {
              first_line: lstack[lstack.length - (len || 1)].first_line,
              last_line: lstack[lstack.length - 1].last_line,
              first_column: lstack[lstack.length - (len || 1)].first_column,
              last_column: lstack[lstack.length - 1].last_column
            }
            if (ranges) {
              yyval._$.range = [
                lstack[lstack.length - (len || 1)].range[0],
                lstack[lstack.length - 1].range[1]
              ]
            }
            r = this.performAction.apply(
              yyval,
              [
                yytext,
                yyleng,
                yylineno,
                sharedState.yy,
                action[1],
                vstack,
                lstack
              ].concat(args)
            )
            if (typeof r !== 'undefined') {
              return r
            }
            if (len) {
              stack = stack.slice(0, -1 * len * 2)
              vstack = vstack.slice(0, -1 * len)
              lstack = lstack.slice(0, -1 * len)
            }
            stack.push(this.productions_[action[1]][0])
            vstack.push(yyval.$)
            lstack.push(yyval._$)
            newState = table[stack[stack.length - 2]][stack[stack.length - 1]]
            stack.push(newState)
            break
          case 3:
            return true
        }
      }
      return true
    }
  }

  /* generated by jison-lex 0.3.4 */
  var lexer = (function () {
    var lexer = {
      EOF: 1,

      parseError: function parseError(str, hash) {
        if (this.yy.parser) {
          this.yy.parser.parseError(str, hash)
        } else {
          throw new Error(str)
        }
      },

      // resets the lexer, sets new input
      setInput: function (input, yy) {
        this.yy = yy || this.yy || {}
        this._input = input
        this._more = this._backtrack = this.done = false
        this.yylineno = this.yyleng = 0
        this.yytext = this.matched = this.match = ''
        this.conditionStack = ['INITIAL']
        this.yylloc = {
          first_line: 1,
          first_column: 0,
          last_line: 1,
          last_column: 0
        }
        if (this.options.ranges) {
          this.yylloc.range = [0, 0]
        }
        this.offset = 0
        return this
      },

      // consumes and returns one char from the input
      input: function () {
        var ch = this._input[0]
        this.yytext += ch
        this.yyleng++
        this.offset++
        this.match += ch
        this.matched += ch
        var lines = ch.match(/(?:\r\n?|\n).*/g)
        if (lines) {
          this.yylineno++
          this.yylloc.last_line++
        } else {
          this.yylloc.last_column++
        }
        if (this.options.ranges) {
          this.yylloc.range[1]++
        }

        this._input = this._input.slice(1)
        return ch
      },

      // unshifts one char (or a string) into the input
      unput: function (ch) {
        var len = ch.length
        var lines = ch.split(/(?:\r\n?|\n)/g)

        this._input = ch + this._input
        this.yytext = this.yytext.substr(0, this.yytext.length - len)
        //this.yyleng -= len;
        this.offset -= len
        var oldLines = this.match.split(/(?:\r\n?|\n)/g)
        this.match = this.match.substr(0, this.match.length - 1)
        this.matched = this.matched.substr(0, this.matched.length - 1)

        if (lines.length - 1) {
          this.yylineno -= lines.length - 1
        }
        var r = this.yylloc.range

        this.yylloc = {
          first_line: this.yylloc.first_line,
          last_line: this.yylineno + 1,
          first_column: this.yylloc.first_column,
          last_column: lines
            ? (lines.length === oldLines.length
                ? this.yylloc.first_column
                : 0) +
              oldLines[oldLines.length - lines.length].length -
              lines[0].length
            : this.yylloc.first_column - len
        }

        if (this.options.ranges) {
          this.yylloc.range = [r[0], r[0] + this.yyleng - len]
        }
        this.yyleng = this.yytext.length
        return this
      },

      // When called from action, caches matched text and appends it on next action
      more: function () {
        this._more = true
        return this
      },

      // When called from action, signals the lexer that this rule fails to match the input, so the next matching rule (regex) should be tested instead.
      reject: function () {
        if (this.options.backtrack_lexer) {
          this._backtrack = true
        } else {
          return this.parseError(
            'Lexical error on line ' +
              (this.yylineno + 1) +
              '. You can only invoke reject() in the lexer when the lexer is of the backtracking persuasion (options.backtrack_lexer = true).\n' +
              this.showPosition(),
            {
              text: '',
              token: null,
              line: this.yylineno
            }
          )
        }
        return this
      },

      // retain first n characters of the match
      less: function (n) {
        this.unput(this.match.slice(n))
      },

      // displays already matched input, i.e. for error messages
      pastInput: function () {
        var past = this.matched.substr(
          0,
          this.matched.length - this.match.length
        )
        return (
          (past.length > 20 ? '...' : '') + past.substr(-20).replace(/\n/g, '')
        )
      },

      // displays upcoming input, i.e. for error messages
      upcomingInput: function () {
        var next = this.match
        if (next.length < 20) {
          next += this._input.substr(0, 20 - next.length)
        }
        return (next.substr(0, 20) + (next.length > 20 ? '...' : '')).replace(
          /\n/g,
          ''
        )
      },

      // displays the character position where the lexing error occurred, i.e. for error messages
      showPosition: function () {
        var pre = this.pastInput()
        var c = new Array(pre.length + 1).join('-')
        return pre + this.upcomingInput() + '\n' + c + '^'
      },

      // test the lexed token: return FALSE when not a match, otherwise return token
      test_match: function (match, indexed_rule) {
        var token, lines, backup

        if (this.options.backtrack_lexer) {
          // save context
          backup = {
            yylineno: this.yylineno,
            yylloc: {
              first_line: this.yylloc.first_line,
              last_line: this.last_line,
              first_column: this.yylloc.first_column,
              last_column: this.yylloc.last_column
            },
            yytext: this.yytext,
            match: this.match,
            matches: this.matches,
            matched: this.matched,
            yyleng: this.yyleng,
            offset: this.offset,
            _more: this._more,
            _input: this._input,
            yy: this.yy,
            conditionStack: this.conditionStack.slice(0),
            done: this.done
          }
          if (this.options.ranges) {
            backup.yylloc.range = this.yylloc.range.slice(0)
          }
        }

        lines = match[0].match(/(?:\r\n?|\n).*/g)
        if (lines) {
          this.yylineno += lines.length
        }
        this.yylloc = {
          first_line: this.yylloc.last_line,
          last_line: this.yylineno + 1,
          first_column: this.yylloc.last_column,
          last_column: lines
            ? lines[lines.length - 1].length -
              lines[lines.length - 1].match(/\r?\n?/)[0].length
            : this.yylloc.last_column + match[0].length
        }
        this.yytext += match[0]
        this.match += match[0]
        this.matches = match
        this.yyleng = this.yytext.length
        if (this.options.ranges) {
          this.yylloc.range = [this.offset, (this.offset += this.yyleng)]
        }
        this._more = false
        this._backtrack = false
        this._input = this._input.slice(match[0].length)
        this.matched += match[0]
        token = this.performAction.call(
          this,
          this.yy,
          this,
          indexed_rule,
          this.conditionStack[this.conditionStack.length - 1]
        )
        if (this.done && this._input) {
          this.done = false
        }
        if (token) {
          return token
        } else if (this._backtrack) {
          // recover context
          for (var k in backup) {
            this[k] = backup[k]
          }
          return false // rule action called reject() implying the next rule should be tested instead.
        }
        return false
      },

      // return next match in input
      next: function () {
        if (this.done) {
          return this.EOF
        }
        if (!this._input) {
          this.done = true
        }

        var token, match, tempMatch, index
        if (!this._more) {
          this.yytext = ''
          this.match = ''
        }
        var rules = this._currentRules()
        for (var i = 0; i < rules.length; i++) {
          tempMatch = this._input.match(this.rules[rules[i]])
          if (tempMatch && (!match || tempMatch[0].length > match[0].length)) {
            match = tempMatch
            index = i
            if (this.options.backtrack_lexer) {
              token = this.test_match(tempMatch, rules[i])
              if (token !== false) {
                return token
              } else if (this._backtrack) {
                match = false
                continue // rule action called reject() implying a rule MISmatch.
              } else {
                // else: this is a lexer rule which consumes input without producing a token (e.g. whitespace)
                return false
              }
            } else if (!this.options.flex) {
              break
            }
          }
        }
        if (match) {
          token = this.test_match(match, rules[index])
          if (token !== false) {
            return token
          }
          // else: this is a lexer rule which consumes input without producing a token (e.g. whitespace)
          return false
        }
        if (this._input === '') {
          return this.EOF
        } else {
          return this.parseError(
            'Lexical error on line ' +
              (this.yylineno + 1) +
              '. Unrecognized text.\n' +
              this.showPosition(),
            {
              text: '',
              token: null,
              line: this.yylineno
            }
          )
        }
      },

      // return next match that has a token
      lex: function lex() {
        var r = this.next()
        if (r) {
          return r
        } else {
          return this.lex()
        }
      },

      // activates a new lexer condition state (pushes the new lexer condition state onto the condition stack)
      begin: function begin(condition) {
        this.conditionStack.push(condition)
      },

      // pop the previously active lexer condition state off the condition stack
      popState: function popState() {
        var n = this.conditionStack.length - 1
        if (n > 0) {
          return this.conditionStack.pop()
        } else {
          return this.conditionStack[0]
        }
      },

      // produce the lexer rule set which is active for the currently active lexer condition state
      _currentRules: function _currentRules() {
        if (
          this.conditionStack.length &&
          this.conditionStack[this.conditionStack.length - 1]
        ) {
          return this.conditions[
            this.conditionStack[this.conditionStack.length - 1]
          ].rules
        } else {
          return this.conditions['INITIAL'].rules
        }
      },

      // return the currently active lexer condition state; when an index argument is provided it produces the N-th previous condition state, if available
      topState: function topState(n) {
        n = this.conditionStack.length - 1 - Math.abs(n || 0)
        if (n >= 0) {
          return this.conditionStack[n]
        } else {
          return 'INITIAL'
        }
      },

      // alias for begin(condition)
      pushState: function pushState(condition) {
        this.begin(condition)
      },

      // return the number of states currently on the stack
      stateStackSize: function stateStackSize() {
        return this.conditionStack.length
      },
      options: { 'case-insensitive': true },
      performAction: function anonymous(
        yy,
        yy_,
        $avoiding_name_collisions,
        YY_START
      ) {
        var YYSTATE = YY_START
        switch ($avoiding_name_collisions) {
          case 0 /* ignore whitespace */:
            break
          case 1:
            return ''
            break
          case 2:
            return ''
            break
          case 3:
            return 42
            break
          case 4:
            return 35
            break
          case 5:
            return 77
            break
          case 6:
            return 78
            break
          case 7:
            return 78
            break
          case 8:
            return 8
            break
          case 9:
            return 6
            break
          case 10:
            return 82
            break
          case 11:
            return 7
            break
          case 12:
            return 9
            break
          case 13:
            return 59
            break
          case 14:
            return 13
            break
          case 15:
            return 15
            break
          case 16:
            return 17
            break
          case 17:
            return 18
            break
          case 18:
            return 19
            break
          case 19:
            return 20
            break
          case 20:
            return 11
            break
          case 21:
            return 62
            break
          case 22:
            return 64
            break
          case 23:
            return 23
            break
          case 24:
            return 25
            break
          case 25:
            return 26
            break
          case 26:
            return 27
            break
          case 27:
            return 30
            break
          case 28:
            return 34
            break
          case 29:
            return 33
            break
          case 30:
            return 65
            break
          case 31:
            return 66
            break
          case 32:
            return 37
            break
          case 33:
            return 41
            break
          case 34:
            return 43
            break
          case 35:
            return 52
            break
          case 36:
            return 54
            break
          case 37:
            return 55
            break
          case 38:
            return 46
            break
          case 39:
            return 48
            break
          case 40:
            return 45
            break
          case 41:
            return 49
            break
          case 42:
            return 56
            break
          case 43:
            return 58
            break
          case 44:
            return 44
            break
          case 45:
            return 83
            break
          case 46:
            return 84
            break
          case 47:
            return 85
            break
          case 48:
            return 86
            break
          case 49:
            return 87
            break
          case 50:
            return 88
            break
          case 51:
            return 89
            break
          case 52:
            return 90
            break
          case 53:
            return 91
            break
          case 54:
            return 92
            break
          case 55:
            return 93
            break
          case 56:
            return 94
            break
          case 57:
            return 95
            break
          case 58:
            return 70
            break
          case 59:
            return 70
            break
          case 60:
            return 72
            break
          case 61:
            return 72
            break
          case 62:
            return 74
            break
          case 63:
            return 74
            break
          case 64:
            return 74
            break
          case 65:
            return 31
            break
          case 66:
            return 36
            break
          case 67:
            return 96
            break
          case 68:
            return 97
            break
          case 69:
            return 98
            break
          case 70:
            return 99
            break
          case 71:
            yy_.yytext = yy.utils.unquoteString(yy_.yytext)
            return 14
            break
          case 72:
            return 38
            break
          case 73:
            return 5
            break
          case 74:
            return 101
            break
          case 75:
            return 103
            break
          case 76:
            return '\\'
            break
          case 77:
            return 28
            break
          case 78:
            return 61
            break
          case 79:
            return 29
            break
          case 80:
            return 57
            break
          case 81:
            return 71
            break
        }
      },
      rules: [
        /^(?:\s+)/i,
        /^(?:[#].*)/i,
        /^(?:\/\/.*)/i,
        /^(?:([_A-Z0-9\/\+]+==))/i,
        /^(?:-?[0-9]+(\.[0-9]+)?\b)/i,
        /^(?:0[xX][0-9A-F]+\b)/i,
        /^(?:false\b)/i,
        /^(?:true\b)/i,
        /^(?:all\b)/i,
        /^(?:reset\b)/i,
        /^(?:clear\b)/i,
        /^(?:build\b)/i,
        /^(?:help\b)/i,
        /^(?:load\b)/i,
        /^(?:get\b)/i,
        /^(?:set\b)/i,
        /^(?:set_save\b)/i,
        /^(?:set_restore\b)/i,
        /^(?:set_reset\b)/i,
        /^(?:preset\b)/i,
        /^(?:motm\b)/i,
        /^(?:add\b)/i,
        /^(?:rep\b)/i,
        /^(?:remove\b)/i,
        /^(?:hide\b)/i,
        /^(?:show\b)/i,
        /^(?:list\b)/i,
        /^(?:select\b)/i,
        /^(?:within\b)/i,
        /^(?:selector\b)/i,
        /^(?:mode\b)/i,
        /^(?:color\b)/i,
        /^(?:material\b)/i,
        /^(?:view\b)/i,
        /^(?:unit\b)/i,
        /^(?:line\b)/i,
        /^(?:listobj\b)/i,
        /^(?:removeobj\b)/i,
        /^(?:rotate\b)/i,
        /^(?:translate\b)/i,
        /^(?:scale\b)/i,
        /^(?:center\b)/i,
        /^(?:url\b)/i,
        /^(?:screenshot\b)/i,
        /^(?:dssp\b)/i,
        /^(?:file_list\b)/i,
        /^(?:file_register\b)/i,
        /^(?:file_delete\b)/i,
        /^(?:preset_add\b)/i,
        /^(?:preset_delete\b)/i,
        /^(?:preset_update\b)/i,
        /^(?:preset_rename\b)/i,
        /^(?:preset_open\b)/i,
        /^(?:create_scenario\b)/i,
        /^(?:reset_scenario\b)/i,
        /^(?:delete_scenario\b)/i,
        /^(?:add_scenario_item\b)/i,
        /^(?:list_scenario\b)/i,
        /^(?:s\b)/i,
        /^(?:mt\b)/i,
        /^(?:m\b)/i,
        /^(?:c\b)/i,
        /^(?:x\b)/i,
        /^(?:y\b)/i,
        /^(?:z\b)/i,
        /^(?:as\b)/i,
        /^(?:of\b)/i,
        /^(?:pdb\b)/i,
        /^(?:delay\b)/i,
        /^(?:prst\b)/i,
        /^(?:desc\b)/i,
        /^(?:((?:"(?:\\.|[^\\"])*"|'(?:\\.|[^\\'])*')))/i,
        /^(?:([_A-Z0-9]+))/i,
        /^(?:$)/i,
        /^(?:\.)/i,
        /^(?:\/)/i,
        /^(?:\\)/i,
        /^(?:-e\b)/i,
        /^(?:-f\b)/i,
        /^(?:-s\b)/i,
        /^(?:-v\b)/i,
        /^(?:=)/i
      ],
      conditions: {
        INITIAL: {
          rules: [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
            19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
            36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
            53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69,
            70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81
          ],
          inclusive: true
        }
      }
    }
    return lexer
  })()
  parser.lexer = lexer
  function Parser() {
    this.yy = {}
  }
  Parser.prototype = parser
  parser.Parser = Parser
  return new Parser()
})()

module.exports = { parser: parser }
