import Palette from './Palette'
import StructuralElement from '../../chem/StructuralElement'

const palette = new Palette('Jmol', 'JM')

palette.colors = [
  /* eslint-disable no-magic-numbers */
  0x0000ff, // blue
  0x0055ff, //
  0x00abff, //
  0x00ffff, // cyan
  0x00ffab, //
  0x00ff55, //
  0x00ff00, // green
  0x55ff00, //
  0xabff00, //
  0xffff00, // yellow
  0xffab00, //
  0xff5500, //
  0xff0000, // red
  0xff0055, //
  0xff00ab, //
  0xff00ff, // magenta
  0xab00ff, //
  0x5500ff //
  /* eslint-enable no-magic-numbers */
]

// DO NOT EDIT MANUALLY! Autogenerated from atom_types.csv by atom_types.py.
palette.elementColors = {
  /* eslint-disable no-magic-numbers */
  H: 0xffffff,
  D: 0xffffc0,
  T: 0xffffa0,
  HE: 0xd9ffff,
  LI: 0xcc80ff,
  BE: 0xc2ff00,
  B: 0xffb5b5,
  C: 0x909090,
  N: 0x3050f8,
  O: 0xff0d0d,
  F: 0x90e050,
  NE: 0xb3e3f5,
  NA: 0xab5cf2,
  MG: 0x8aff00,
  AL: 0xbfa6a6,
  SI: 0xf0c8a0,
  P: 0xff8000,
  S: 0xffff30,
  CL: 0x1ff01f,
  AR: 0x80d1e3,
  K: 0x8f40d4,
  CA: 0x3dff00,
  SC: 0xe6e6e6,
  TI: 0xbfc2c7,
  V: 0xa6a6ab,
  CR: 0x8a99c7,
  MN: 0x9c7ac7,
  FE: 0xe06633,
  CO: 0xf090a0,
  NI: 0x50d050,
  CU: 0xc88033,
  ZN: 0x7d80b0,
  GA: 0xc28f8f,
  GE: 0x668f8f,
  AS: 0xbd80e3,
  SE: 0xffa100,
  BR: 0xa62929,
  KR: 0x5cb8d1,
  RB: 0x702eb0,
  SR: 0x00ff00,
  Y: 0x94ffff,
  ZR: 0x94e0e0,
  NB: 0x73c2c9,
  MO: 0x54b5b5,
  TC: 0x3b9e9e,
  RU: 0x248f8f,
  RH: 0x0a7d8c,
  PD: 0x006985,
  AG: 0xc0c0c0,
  CD: 0xffd98f,
  IN: 0xa67573,
  SN: 0x668080,
  SB: 0x9e63b5,
  TE: 0xd47a00,
  I: 0x940094,
  XE: 0x429eb0,
  CS: 0x57178f,
  BA: 0x00c900,
  LA: 0x70d4ff,
  CE: 0xffffc7,
  PR: 0xd9ffc7,
  ND: 0xc7ffc7,
  PM: 0xa3ffc7,
  SM: 0x8fffc7,
  EU: 0x61ffc7,
  GD: 0x45ffc7,
  TB: 0x30ffc7,
  DY: 0x1fffc7,
  HO: 0x00ff9c,
  ER: 0x00e675,
  TM: 0x00d452,
  YB: 0x00bf38,
  LU: 0x00ab24,
  HF: 0x4dc2ff,
  TA: 0x4da6ff,
  W: 0x2194d6,
  RE: 0x267dab,
  OS: 0x266696,
  IR: 0x175487,
  PT: 0xd0d0e0,
  AU: 0xffd123,
  HG: 0xb8b8d0,
  TL: 0xa6544d,
  PB: 0x575961,
  BI: 0x9e4fb5,
  PO: 0xab5c00,
  AT: 0x754f45,
  RN: 0x428296,
  FR: 0x420066,
  RA: 0x007d00,
  AC: 0x70abfa,
  TH: 0x00baff,
  PA: 0x00a1ff,
  U: 0x008fff,
  NP: 0x0080ff,
  PU: 0x006bff,
  AM: 0x545cf2,
  CM: 0x785ce3,
  BK: 0x8a4fe3,
  CF: 0xa136d4,
  ES: 0xb31fd4,
  FM: 0xb31fba,
  MD: 0xb30da6,
  NO: 0xbd0d87,
  LR: 0xc70066,
  RF: 0xcc0059,
  DB: 0xd1004f,
  SG: 0xd90045,
  BH: 0xe00038,
  HS: 0xe6002e,
  MT: 0xeb0026
  /* eslint-enable no-magic-numbers */
}

palette.defaultResidueColor = 0xbea06e

// DO NOT EDIT MANUALLY! Autogenerated from residue_types.csv by residue_types.py.
palette.residueColors = {
  /* eslint-disable no-magic-numbers */
  ALA: 0xc8c8c8,
  ARG: 0x145aff,
  ASN: 0x00dcdc,
  ASP: 0xe60a0a,
  CYS: 0xe6e600,
  GLN: 0x00dcdc,
  GLU: 0xe60a0a,
  GLY: 0xebebeb,
  HIS: 0x8282d2,
  ILE: 0x0f820f,
  LEU: 0x0f820f,
  LYS: 0x145aff,
  MET: 0xe6e600,
  PHE: 0x3232aa,
  PRO: 0xdc9682,
  SER: 0xfa9600,
  THR: 0xfa9600,
  TRP: 0xb45ab4,
  TYR: 0x3232aa,
  VAL: 0x0f820f,
  A: 0xa0a0ff,
  C: 0xff8c4b,
  G: 0xff7070,
  I: 0x80ffff,
  T: 0xa0ffa0,
  U: 0xff8080,
  DA: 0xa0a0ff,
  DC: 0xff8c4b,
  DG: 0xff7070,
  DI: 0x80ffff,
  DT: 0xa0ffa0,
  DU: 0xff8080,
  '+A': 0xa0a0ff,
  '+C': 0xff8c4b,
  '+G': 0xff7070,
  '+I': 0x80ffff,
  '+T': 0xa0ffa0,
  '+U': 0xff8080
  /* eslint-enable no-magic-numbers */
}

palette.chainColors = [
  // ' '->0 'A'->1, 'B'->2
  0xffffffff, // ' ' & '0' white
  //
  0xffc0d0ff, // skyblue
  0xffb0ffb0, // pastel green
  0xffffc0c8, // pink
  0xffffff80, // pastel yellow
  0xffffc0ff, // pastel magenta
  0xffb0f0f0, // pastel cyan
  0xffffd070, // pastel gold
  0xfff08080, // lightcoral

  0xfff5deb3, // wheat
  0xff00bfff, // deepskyblue
  0xffcd5c5c, // indianred
  0xff66cdaa, // mediumaquamarine
  0xff9acd32, // yellowgreen
  0xffee82ee, // violet
  0xff00ced1, // darkturquoise
  0xff00ff7f, // springgreen
  0xff3cb371, // mediumseagreen

  0xff00008b, // darkblue
  0xffbdb76b, // darkkhaki
  0xff006400, // darkgreen
  0xff800000, // maroon
  0xff808000, // olive
  0xff800080, // purple
  0xff008080, // teal
  0xffb8860b, // darkgoldenrod
  0xffb22222 // firebrick
]

const StructuralElementType = StructuralElement.Type

palette.secondaryColors = {
  [StructuralElementType.HELIX_ALPHA]: 0xff0080,
  [StructuralElementType.HELIX_PI]: 0x600080,
  [StructuralElementType.HELIX_310]: 0xa00080,
  [StructuralElementType.STRAND]: 0xffc800,
  [StructuralElementType.TURN]: 0x6080ff,
  dna: 0xae00fe,
  rna: 0xfd0162
}

export default palette
