import Palette from './Palette'

const palette = new Palette('CPK', 'CP')

// DO NOT EDIT MANUALLY! Autogenerated from atom_types.csv by atom_types.py.
palette.elementColors = {
  /* eslint-disable no-magic-numbers */
  H: 0xffffff,
  C: 0x202020,
  N: 0x2060ff,
  O: 0xee2010,
  F: 0x00ff00,
  P: 0x8020ff,
  S: 0xffff00,
  CL: 0x00bb00,
  FE: 0xd0d0d0,
  CO: 0xd0d0d0,
  NI: 0xd0d0d0,
  CU: 0xd0d0d0,
  BR: 0x008800,
  I: 0x005500
  /* eslint-enable no-magic-numbers */
}

export default palette
